import { getPageSetFromPath } from "@pageDefinitions/pageSets";
import { findEntryPointLocation } from "src/pageDefinitions/actions/survey/util";
import getStore from "src/utils/redux/store";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { gotoPage } from "./pageSet";
import { getHistory } from "../components/PageSetRouter";
import { TreatmentOptions } from "@components/mht/utils/TreatmentOptions";

enum HRTPlanEntryPoints {
  cream = "hrtCreamPlan",
  patch = "hrtPatchPlan",
}

const TREATMENTS_MAP = {
  [TreatmentOptions.HORMONAL_CREAM_OR_GEL]: HRTPlanEntryPoints.cream,
  [TreatmentOptions.ESTROGEN_PATCH]: HRTPlanEntryPoints.patch,
};

export default {
  async navigateToHRTPlanRevealPage() {
    const store = getStore();
    const history = getHistory();
    const pageSet = await getPageSetFromPath(
      window.location.pathname,
      getStore().getState()
    );

    const {
      takingHRT,
      previousHRTTreatment,
      previousHRTPreference,
      currentHRTTreatment,
      currentHRTPreference,
    } = getSurveyAnswers();

    let entryPoint = HRTPlanEntryPoints.cream;
    const treatment = takingHRT.includes("yes")
      ? currentHRTTreatment
      : previousHRTTreatment;
    const preference = takingHRT.includes("yes")
      ? currentHRTPreference
      : previousHRTPreference;

    const usedTreatments =
      treatment &&
      Object.keys(TREATMENTS_MAP).filter((treatmentOption) =>
        treatment.includes(treatmentOption)
      );

    if (usedTreatments && usedTreatments.length === 1) {
      const oppositeTreatment = Object.keys(TREATMENTS_MAP).find(
        (key) => key !== usedTreatments[0]
      );

      entryPoint = preference.includes("yes")
        ? TREATMENTS_MAP[usedTreatments[0]]
        : TREATMENTS_MAP[oppositeTreatment];
    }

    const entryPointLocation = findEntryPointLocation(pageSet, entryPoint);
    return gotoPage(pageSet, { store, history }, false, entryPointLocation);
  },
};
