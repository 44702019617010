import { isCalifornia, isEnLanguage } from ".";
import { shift_months, getDayDelta, shift_days } from "../datetime";
import { getCountryCode } from "../meristemContext";
import { ServerContextState } from "../redux/slices/serverContext";
import getStore from "../redux/store";
import { CancellationReasonOptions } from "src/pages/subscription/[context]/cancellationReason";
import { isSubscriptionPremiumTier } from "@utils/userSegment/features";
import { isMedTierPlanId, isPremiumTierPlanId } from "@utils/plans";

export const isSubscriptionInvalid = () => {
  const { serverContext } = getStore().getState();
  return (
    serverContext.result !== "success" ||
    (serverContext.subscription_status !== "ACTIVE" &&
      serverContext.subscription_status !== "PAST_DUE") ||
    serverContext.has_expiring_subscription ||
    serverContext.installment_billing?.canceled
  );
};

export function showDisplayTimeLeftPage() {
  const { serverContext, userData } = getStore().getState();
  const nextBillingDate = new Date(serverContext.expiry_time);
  const planDuration = serverContext.plan_duration;
  const previousBillingDate = shift_months(-planDuration, nextBillingDate);

  const daysCompleted = getDayDelta(previousBillingDate, new Date());
  const planNrOfDays = getDayDelta(previousBillingDate, nextBillingDate);
  const progress = daysCompleted / planNrOfDays;

  const country = serverContext.billing_country || getCountryCode();

  if (
    userData.billing_reminder?.optIn || // if the user already opted in don't show the page again
    isCalifornia() || // the page isn't compliant with CA cancel flow
    serverContext.is_in_trial ||
    progress > 0.75 ||
    serverContext.subscription_is_hm ||
    serverContext.subscription_status !== "ACTIVE" ||
    country !== "US" ||
    isMedTierPlanId(serverContext.current_plan_id)
  ) {
    return false;
  }
  return true;
}

export function showDisplayTimeLeftPageTrialists() {
  const { serverContext, userData } = getStore().getState();
  const {
    trial_duration: trialDuration,
    expiry_time: expiryTime,
    current_plan_id: planId,
  } = serverContext;
  const subscriptionStartDate = new Date(expiryTime);
  const trialStartDate = shift_days(-trialDuration, subscriptionStartDate);
  const now = new Date();
  // NOTE: not using getDayDelta since we want the exact date difference, not rounded
  //       because the billing reminder is sent on Day 3 (or 10 for 15 day trials)
  const daysCompleted =
    (now.getTime() - trialStartDate.getTime()) / (1000 * 60 * 60 * 24);

  const country = serverContext.billing_country || getCountryCode();
  const dateIsEligible =
    (trialDuration <= 8 && daysCompleted <= 3) ||
    (trialDuration > 8 && daysCompleted <= 10);

  return (
    serverContext.is_in_trial &&
    !userData.billing_reminder?.trialOptIn &&
    !isCalifornia() &&
    country === "US" &&
    isEnLanguage() &&
    dateIsEligible &&
    !isMedTierPlanId(planId)
  );
}

function getCancellationReason(): CancellationReasonOptions | undefined {
  const { currentAnswers } = getStore().getState();
  const counterOfferAnswers =
    currentAnswers.questionAnswers?.["userData.counterOfferAnswers"];
  return counterOfferAnswers?.cancellationReason?.[0];
}

export function isIneligibleForCounterOffer() {
  const { serverContext, userData } = getStore().getState();
  const {
    has_accepted_counter_offer,
    has_pending_subscription,
    installment_billing,
    subscription_status,
    subscription_statuses,
    eligible_for_counter_offer,
    has_pending_bms_migration,
  } = serverContext;
  const installmentBillingIsIneligible =
    !!installment_billing && !installment_billing.eligible_for_counter_offer;
  return (
    userData.vip ||
    subscription_statuses == null ||
    subscription_statuses.includes("COUNTEROFFER") ||
    has_accepted_counter_offer ||
    // If the pending subscription is a BMS migration, they are still eligible
    (has_pending_subscription && !has_pending_bms_migration) ||
    !eligible_for_counter_offer ||
    installmentBillingIsIneligible ||
    subscription_status === "PAST_DUE" ||
    isSubscriptionInvalid()
  );
}

function isEligibleForPercentDiscountBase() {
  const { serverContext } = getStore().getState();
  return isEnLanguage() && serverContext.eligible_for_percent_discount_offer;
}

export function isEligibleForPercentDiscount() {
  return (
    isEligibleForPercentDiscountBase() &&
    getCancellationReason() === "expensive"
  );
}

export function isEligibleForPercentDiscountPost() {
  return (
    isEligibleForPercentDiscountBase() &&
    getCancellationReason() !== "expensive" &&
    getCancellationReason() !== "notSatisfiedPremium"
  );
}

export function isEligibleForPlanDiscount() {
  const { serverContext } = getStore().getState();
  return !serverContext.installment_billing || serverContext.is_in_trial;
}

export function isEligibleForTrialExtension() {
  const { serverContext } = getStore().getState();
  return (
    getCancellationReason() === "trialTime" &&
    !serverContext.subscription_statuses.includes("TRIAL_EXTENSION") &&
    serverContext.is_in_trial &&
    !isPremiumTierPlanId(serverContext.current_plan_id)
  );
}

function isEligibleForPauseBase() {
  const { serverContext } = getStore().getState();
  return (
    !hasAcceptedPause(serverContext) &&
    (!serverContext.installment_billing || serverContext.is_in_trial)
  );
}

export function isEligibleForPause() {
  const cancellationReason = getCancellationReason();
  return (
    isEligibleForPauseBase() &&
    cancellationReason !== "trialTime" &&
    cancellationReason !== "expensive" &&
    cancellationReason !== "notSatisfiedPremium"
  );
}

export function isEligibleForPauseDiscounted() {
  return (
    isEligibleForPauseBase() &&
    !isEligibleForPause() &&
    getCancellationReason() === "expensive" &&
    !isSubscriptionPremiumTier()
  );
}

function hasAcceptedPause(serverContext: ServerContextState) {
  return serverContext.subscription_statuses.includes("PAUSE_SUBSCRIPTION");
}

export function isEligibleForPremiumDowngrade() {
  return isSubscriptionPremiumTier();
}

export function isEligibleForMedUpgrade() {
  const { serverContext } = getStore().getState();
  const cancellationReason = getCancellationReason();
  return (
    !serverContext.is_in_trial &&
    serverContext.billing_country === "US" &&
    serverContext.is_eligible_for_med_upgrade &&
    ["overwhelming", "notSatisfiedPremium", "noResults", "expensive"].includes(
      cancellationReason
    )
  );
}
