import BrainFog from "../images/brainFog.svg";
import DecreasedLibido from "../images/decreasedLibido.svg";
import HotFlashes from "../images/hotFlashes.svg";
import JointPain from "../images/jointPain.svg";
import MoodChanges from "../images/moodChanges.svg";
import NightSweats from "../images/nightSweats.svg";
import NoEnergy from "../images/noEnergy.svg";
import PoorSleep from "../images/poorSleep.svg";
import UrinaryConcerns from "../images/urinaryConcerns.svg";
import VaginalDryness from "../images/vaginalDryness.svg";
import WeightGain from "../images/weightGain.svg";
import WeightLoss from "../images/weightLoss.svg";
import ReducedHotFlashes from "../images/reducedHotFlashes.svg";
import MentalClarity from "../images/mentalClarity.svg";
import BetterSleep from "../images/betterSleep.svg";
import BalancedMood from "../images/balancedMood.svg";
import HigherEnergy from "../images/higherEnergy.svg";
import IncreasedLibido from "../images/increasedLibido.svg";
import VaginalLubrication from "../images/vaginalLubrication.svg";
import UrinaryHealth from "../images/urinaryHealth.svg";
import NightSweatsRelief from "../images/nightSweatsRelief.svg";
import ReducedJointPain from "../images/reducedJointPain.svg";
import { getSurveyAnswers } from "src/hooks/survey/answers";

export enum MenopauseSymptoms {
  WEIGHT_GAIN = "weightGain",
  HOT_FLASHES = "hotFlashes",
  BRAIN_FOG = "brainFog",
  SLEEP_DISTURBANCES = "sleepDisturbances",
  CHANGES_IN_MOOD = "changesInMood",
  NIGHT_SWEATS = "nightSweats",
  NO_ENERGY = "noEnergy",
  DECREASED_LIBIDO = "decreasedLibido",
  VAGINAL_DISCOMFORT = "vaginalDiscomfort",
  URINARY_CONCERNS = "urinaryConcerns",
  JOINT_PAIN = "jointPain",
}

export const menopauseSymptomsConfig = [
  {
    id: MenopauseSymptoms.WEIGHT_GAIN,
    negativeImage: WeightGain,
    positiveImage: WeightLoss,
    priority: 1,
  },
  {
    id: MenopauseSymptoms.HOT_FLASHES,
    negativeImage: HotFlashes,
    positiveImage: ReducedHotFlashes,
    priority: 2,
  },
  {
    id: MenopauseSymptoms.BRAIN_FOG,
    negativeImage: BrainFog,
    positiveImage: MentalClarity,
    priority: 3,
  },
  {
    id: MenopauseSymptoms.SLEEP_DISTURBANCES,
    negativeImage: PoorSleep,
    positiveImage: BetterSleep,
    priority: 4,
  },
  {
    id: MenopauseSymptoms.CHANGES_IN_MOOD,
    negativeImage: MoodChanges,
    positiveImage: BalancedMood,
    priority: 5,
  },
  {
    id: MenopauseSymptoms.NIGHT_SWEATS,
    negativeImage: NightSweats,
    positiveImage: NightSweatsRelief,
    priority: 6,
  },
  {
    id: MenopauseSymptoms.NO_ENERGY,
    negativeImage: NoEnergy,
    positiveImage: HigherEnergy,
    priority: 7,
  },
  {
    id: MenopauseSymptoms.DECREASED_LIBIDO,
    negativeImage: DecreasedLibido,
    positiveImage: IncreasedLibido,
    priority: 8,
  },
  {
    id: MenopauseSymptoms.VAGINAL_DISCOMFORT,
    negativeImage: VaginalDryness,
    positiveImage: VaginalLubrication,
    priority: 9,
  },
  {
    id: MenopauseSymptoms.URINARY_CONCERNS,
    negativeImage: UrinaryConcerns,
    positiveImage: UrinaryHealth,
    priority: 10,
  },
  {
    id: MenopauseSymptoms.JOINT_PAIN,
    negativeImage: JointPain,
    positiveImage: ReducedJointPain,
    priority: 11,
  },
];

export const getTopSymptomsBasedOnUserSelected = (
  limit: number,
  useOnlyUserSelected: boolean,
  excludeWeightGain: boolean
): typeof menopauseSymptomsConfig => {
  const { menopauseSymptoms: userSelectedMenopauseSymptoms } =
    getSurveyAnswers();
  const topSymptoms = [];

  const menopauseSymptomsData = excludeWeightGain
    ? menopauseSymptomsConfig.filter(
        (symptom) => symptom.id !== MenopauseSymptoms.WEIGHT_GAIN
      )
    : menopauseSymptomsConfig;
  menopauseSymptomsData.sort(
    (symptom1, symptom2) => symptom1.priority - symptom2.priority
  );

  for (const symptom of menopauseSymptomsData) {
    if (userSelectedMenopauseSymptoms?.includes(symptom.id)) {
      topSymptoms.push(symptom);
    }

    if (topSymptoms.length === limit) {
      break;
    }
  }

  if (useOnlyUserSelected || topSymptoms.length === limit) return topSymptoms;

  for (const symptom of menopauseSymptomsData) {
    if (!topSymptoms.find((topSymptom) => topSymptom.id === symptom.id)) {
      topSymptoms.push(symptom);
    }

    if (topSymptoms.length === limit) {
      break;
    }
  }
  return topSymptoms;
};
