/* eslint-disable no-restricted-globals */
import buyflowGoto, { linkTo as buyflowLinkTo } from "./buyflow";
import purchaseGoto from "./purchase";
import offersGoto from "./offers";
import enrollmentGoto from "./enrollment";
import telehealthGoto from "./telehealth";
import dynamicSurveyGoto from "./dynamic-survey";
import noomPremiumGoto from "./noomPremium";
import probioticsDeliveryGoto from "./probiotics-delivery";
import pageSetGoto from "./pageSet";
import courseGoto from "./course";
import counterOfferGoto from "./counterOffer";
import checkInGoto from "./check-in";
import referralsGoto from "./socialRoutes";
import paymentResultsGoTo from "./paymentResults";
import hrtPaymentGoTo from "./hrtPayment";
import { globalPush } from "../components/PageSetRouter";
import { trackPageBail } from "src/utils/monitoring/events/errors";

export const linkTo = {
  ...buyflowLinkTo,
};

const gotoHandlers = {
  ...pageSetGoto,
  ...buyflowGoto,
  ...purchaseGoto,
  ...offersGoto,
  ...enrollmentGoto,
  ...telehealthGoto,
  ...courseGoto,
  ...noomPremiumGoto,
  ...probioticsDeliveryGoto,
  ...referralsGoto,
  ...counterOfferGoto,
  ...checkInGoto,
  ...dynamicSurveyGoto,
  ...paymentResultsGoTo,
  ...hrtPaymentGoTo,

  // loginBack() should be removed once Login page is ported to LBF
  loginBack() {
    history.back();
  },

  currentSurvey(questionId: string, currentQuestionId = "") {
    // WARN: We assume hash router for all surveys at this point.
    const url = new URL(location.href);
    const pathSansTailingSlash = url.hash.replace(/\/$/, "");
    // istanbul ignore if : sanity
    if (!pathSansTailingSlash.endsWith(currentQuestionId)) {
      throw new Error(
        `Unexpected survey path: ${pathSansTailingSlash} expected ${currentQuestionId}`
      );
    }

    const surveyPath = currentQuestionId
      ? pathSansTailingSlash.slice(0, -currentQuestionId.length - 1)
      : pathSansTailingSlash;
    url.hash = `${surveyPath}/${questionId}`;
    globalPush(`${url.pathname}${url.search}${url.hash}`);
  },
} as const;

export default {
  ...gotoHandlers,

  bail<K extends keyof typeof gotoHandlers>(
    goto: K,
    args: Parameters<typeof gotoHandlers[K]>,
    error?: string
  ) {
    trackPageBail(goto, error);

    // @ts-expect-error it's fine
    gotoHandlers[goto](...args);
  },
};
