import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum MhtRolloutUserType {
  HOLDBACK = "HOLDBACK",
  VARIATION = "VARIATION",
  CONTROL = "CONTROL",
  FORCED_ENABLED = "FORCED_ENABLED",
  FORCED_DISABLED = "FORCED_DISABLED",
  HRT_PROMO_LP_TRAFFIC = "HRT_PROMO_LP_TRAFFIC",
}

export type PersistentBrowserFlagsState = {
  mhtRolloutUserType?: MhtRolloutUserType;
};

// Adding this state in order to preserve flags on device instead of preserving them just per session (browser session).
const initialState: PersistentBrowserFlagsState = {
  mhtRolloutUserType: undefined,
};

const persistentBrowserFlagsSlice = createSlice({
  name: "persistentBrowserFlags",
  initialState,
  reducers: {
    setMhtRolloutUserType(state, action: PayloadAction<MhtRolloutUserType>) {
      return {
        ...state,
        mhtRolloutUserType: action.payload,
      };
    },
  },
});

export const { setMhtRolloutUserType } = persistentBrowserFlagsSlice.actions;

export default persistentBrowserFlagsSlice;
