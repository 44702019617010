export const landingLayer = "landing";
export const mainSurveyLayer = "main-survey";
export const paymentSurveyLayer = "payment-survey";
export const paymentCheckoutLayer = "payment-checkout";
export const noomPremiumLayer = "noom-premium";
export const noomPremiumSurveyLayer = "noom-premium-survey";
export const noomPremiumFitonSurveyLayer = "noom-premium-fiton-survey";
export const noomSummitSurveyLayer = "noom-summit-survey";
export const addonsLayer = "addons";
export const fastForwardLayer = "fast-forward";
export const enrollmentLayer = "enrollment";
export const telehealthFeedbackLayer = "telehealth-feedback";
export const noomMedOfferLayer = "noom-med-offer";
export const noomWeightMedBranchingLayer = "main-survey-nw-med-branching";
export const hrtSurveyLayer = "mht-survey";

export const HEALTHY_WEIGHT_CURRICULUM_PREFIX = "HEALTHY_WEIGHT";
