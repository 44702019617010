import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { HeaderToolbar } from "../core/HeaderToolbar";
import { FullWidth, MainColumn } from "../core/BodyLayout";
import { useQuickSilverClickLoader } from "src/test/quickSilver/helpers/useQuickSilverClickLoader";
import { NoomLogo } from "../Logo";
import NoomForWorkLogo from "src/static/noom_for_work_logo.png";
import SurveySkipButton from "src/components/linear-buyflow/SurveySkipButton";
import { BuyFlowDesigns, Design } from "src/design-system/styles/designs";
import NoomWhiteLogo from "./images/NoomMedWordmarkWhite.svg";
import NoomBlackLogo from "./images/NoomMedWordmark.svg";
import { fontTitle } from "src/design-system/styles/fonts";
import { textTruncate } from "src/design-system/styles/utils";
import { Avatar } from "./Avatar";
import { compassColors } from "src/utils/styling";
import BlackCloseButton from "./images/Close.svg";
import WhiteCloseButton from "./images/whiteXButton.svg";

const Content = styled(MainColumn)`
  display: grid;
  // Paired 1fr ensures that the logo is centered
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 48px;
`;

const ForWorkLogo = styled.img`
  height: 25px;
  margin: 14px;
  grid-column: 2;
  grid-row: 1;
`;

const RightContainer = styled.div`
  width: 100%;
  text-align: right;
  flex: 1;
`;

const LeftContainer = styled.div`
  width: 100%;
  text-align: left;
  flex: 1;
`;

const Email = styled.div`
  ${fontTitle};
  ${textTruncate};
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvatarContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const ExitIcon = styled.img`
  width: 24px;
`;

type HeaderProps = {
  pill?: boolean;
  displayLogo?: boolean;
  pillElement?: JSX.Element;
  children?: ReactNode;
  className?: string;
  design?: BuyFlowDesigns;
  redesignHeaderColor?: compassColors.white | compassColors.black;
  userLoggedInName?: string;
  exitFunction?: () => void;
};

function getLogo(
  logo: boolean,
  redesignHeaderColor: compassColors.white | compassColors.black
) {
  if (!logo) return null;
  return redesignHeaderColor === compassColors.black
    ? NoomBlackLogo
    : NoomWhiteLogo;
}

function DefaultHeader({
  pill = true,
  displayLogo = true,
  pillElement,
  children,
  className,
}: {
  pill?: boolean;
  displayLogo?: boolean;
  pillElement?: JSX.Element;
  children?: ReactNode;
  className?: string;
}) {
  const onClickQuickSilverLoader = useQuickSilverClickLoader();

  return (
    <HeaderToolbar className={className}>
      <Content>
        {children}
        {displayLogo && (
          <NoomLogo
            showPill={pill}
            pillElement={pillElement}
            css={{ height: 19, margin: 14, gridColumn: 2, gridRow: 1 }}
            onSecretClick={onClickQuickSilverLoader} // onClick used for debug purposes to load quicksilver
          />
        )}
      </Content>
    </HeaderToolbar>
  );
}

export function ForWorkHeader({
  handleSkip,
  children,
}: {
  handleSkip?: () => void;
  children?: ReactNode;
}) {
  return (
    <HeaderToolbar>
      <Content>
        {children}
        <ForWorkLogo src={NoomForWorkLogo} alt="" />
        {handleSkip && <SurveySkipButton onClickSkip={handleSkip} />}
      </Content>
    </HeaderToolbar>
  );
}

export function RedesignHeader({
  children,
  redesignHeaderColor = compassColors.black,
  userLoggedInName,
  displayLogo = true,
  exitFunction,
}: {
  children?: ReactNode;
  redesignHeaderColor?: compassColors.white | compassColors.black;
  userLoggedInName?: string;
  displayLogo?: boolean;
  exitFunction?: () => void;
}) {
  function getRightContainer() {
    if (exitFunction)
      return (
        <RightContainer>
          <ExitIcon
            src={
              redesignHeaderColor === compassColors.white
                ? WhiteCloseButton
                : BlackCloseButton
            }
            onClick={() => exitFunction()}
          />
        </RightContainer>
      );

    if (userLoggedInName)
      return (
        <RightContainer>
          <Email css={{ color: redesignHeaderColor }}>{userLoggedInName}</Email>
          <AvatarContainer>
            <Avatar color={compassColors.black} email={userLoggedInName} />
          </AvatarContainer>
        </RightContainer>
      );

    return null;
  }

  return (
    <FullWidth>
      <Content>
        <LeftContainer>{children}</LeftContainer>
        <img src={getLogo(displayLogo, redesignHeaderColor)} alt="" />
        {getRightContainer()}
      </Content>
    </FullWidth>
  );
}

export function Header(props: HeaderProps) {
  switch (props.design) {
    case Design.REDESIGN:
      return <RedesignHeader {...props} />;

    default:
      return <DefaultHeader {...props} />;
  }
}
