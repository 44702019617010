import { useCallback, useEffect, useMemo } from "react";
import { AdditionalErrorFields } from "src/components/core/AddressForm/AddressForm";
import {
  setAddress,
  toggleSameAsBillingAddress,
  failAddressLineMDIValidation,
} from "src/utils/redux/slices/checkoutPhysicalAddress/checkoutPhysicalAddress";
import { typedKeys } from "src/utils/typeWrappers";
import { isValidZipCode } from "src/utils/validate";
import {
  useAppDispatch,
  useCheckout,
  useCheckoutPhysicalAddress,
  useCoreState,
  useCoreStore,
} from "../redux";
import {
  failNoPOBoxValidation,
  failLocationMedValidation,
} from "src/utils/redux/slices/checkoutPhysicalAddress/action-creators";
import i18n from "src/locales";
import { BillingAddress, paymentTypes } from "src/utils/redux/slices/checkout";
import { calculateSalesTax } from "../billing-info";
import {
  acceptedNoomClinicalOffer,
  isSelectedMedPlanWithMedication,
  getSelectedMedPlanType,
  isHRTSurvey,
} from "src/utils/userSegment/features";
import getStore from "src/utils/redux/store";
import { selectResolvedPhysicalAddress } from "src/utils/redux/slices/checkoutPhysicalAddress/address-selectors";
import zipState from "zip-state";
import { requestAddressValidation } from "src/utils/redux/slices/checkoutPhysicalAddress/address-thunks";
import { getFullName } from "src/utils/name";
import { getEligibleMedProducts } from "@utils/medEligibility";
import {
  MedEligibleCountries,
  MhtEligibleCountries,
  MhtIneligibleStates,
} from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";

export const MAX_MDI_ADDRESS_LINE_LENGTH = 35;

export function requiresPhysicalAddress(state = getStore().getState()) {
  return acceptedNoomClinicalOffer(state) || isHRTSurvey();
}

function requiresStrictAddressValidation(state = getStore().getState()) {
  return (
    (isSelectedMedPlanWithMedication() && acceptedNoomClinicalOffer(state)) ||
    isHRTSurvey()
  );
}

export function usePhysicalAddressForm() {
  const store = useCoreStore();
  const {
    address,
    isSameAsBillingAddress,
    addressInvalid,
    suggestedAddress,
    chosenModalAddress,
    acceptedSuggestedAddress,
    shouldUpdateFormAddress,
    isAddressLineInvalid,
  } = useCheckoutPhysicalAddress();
  const checkout = useCheckout();

  const coreState = useCoreState();
  const dispatch = useAppDispatch();

  const showNameFields =
    requiresPhysicalAddress(coreState) && isSelectedMedPlanWithMedication();

  const updatePhysicalAddress = useCallback(
    (newAddr: BillingAddress) => {
      const newName = getFullName(newAddr.firstName, newAddr.lastName);

      const addr = {
        ...newAddr,
        ...(newName && {
          name: newName,
        }),
      };

      const { region, zipcode } = addr;
      dispatch(setAddress(addr));
      if (
        (region && address?.region !== region) ||
        (zipcode && address?.zipcode !== zipcode)
      ) {
        calculateSalesTax(store);
      }
    },
    [dispatch, address, store]
  );

  const togglePhysicalAddressSameAsBilling = useCallback(
    (sameAsBillingAddress: boolean) => {
      dispatch(toggleSameAsBillingAddress(sameAsBillingAddress));
      calculateSalesTax(store);
    },
    [dispatch, store]
  );

  useEffect(() => {
    if (checkout.paymentType !== paymentTypes.CREDIT_CARD) {
      togglePhysicalAddressSameAsBilling(false);
    }
  }, [checkout.paymentType, togglePhysicalAddressSameAsBilling]);

  const shouldShowSameAsBillingAddressToggle = useMemo(() => {
    return (
      checkout.paymentType === paymentTypes.CREDIT_CARD && !checkout.walletPay
    );
  }, [checkout.paymentType, checkout.walletPay]);

  function getNoPOBoxInAddressValidation() {
    if (requiresStrictAddressValidation()) {
      // match "po box 123", "P.O. box", "Post office box", "post box", "postal box"
      // should not match "p box", "pobox", "inspo boxley"
      // could let in mistakes such as "po box123" or "post office. box"
      const PO_BOX_REGEX =
        // eslint-disable-next-line no-useless-escape
        /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;

      const enteredAddress = selectResolvedPhysicalAddress(coreState);
      const isAddressPOBox =
        PO_BOX_REGEX.test(enteredAddress.address1) ||
        PO_BOX_REGEX.test(enteredAddress.address2);

      if (isAddressPOBox) {
        dispatch(failNoPOBoxValidation());
        return ["poBoxAsAddress"];
      }
    }

    return [];
  }

  function getAddressLineMDIValidation(): AdditionalErrorFields {
    if (requiresStrictAddressValidation()) {
      let isAddressInvalid = false;
      const { address1, address2 } = selectResolvedPhysicalAddress(coreState);

      if (
        address1.length > MAX_MDI_ADDRESS_LINE_LENGTH &&
        address2?.length > MAX_MDI_ADDRESS_LINE_LENGTH
      ) {
        isAddressInvalid = true;
      } else {
        const { addressLine1, addressLine2 } = getSplitAddressLine({
          address1,
          address2,
        });

        if (
          addressLine1.length > MAX_MDI_ADDRESS_LINE_LENGTH ||
          addressLine2.length > MAX_MDI_ADDRESS_LINE_LENGTH
        ) {
          isAddressInvalid = true;
        }
      }

      if (isAddressInvalid) {
        dispatch(failAddressLineMDIValidation());

        return {
          address1: {
            type: "tooLong",
            message: i18n.t("payment:homeAddressErrors:tooLong"),
          },
        };
      }
    }

    return {};
  }

  function getZipCodeStateEligibleValidation() {
    const enteredAddress = selectResolvedPhysicalAddress(coreState);
    const { zipcode, country } = enteredAddress;

    if (!zipcode) {
      return [];
    }
    const state = zipState(zipcode);

    if (acceptedNoomClinicalOffer()) {
      if (!(country in MedEligibleCountries)) {
        dispatch(failLocationMedValidation());
        return ["country"];
      }
      const selectedMedPlanType = getSelectedMedPlanType();
      const eligibleProducts = getEligibleMedProducts({
        stateOverwrite: state,
        checkBMI: true,
        checkHealthRisks: true,
        checkState: true,
      });

      if (!eligibleProducts.includes(selectedMedPlanType)) {
        dispatch(failLocationMedValidation());
        return ["postcode"];
      }
    } else if (isHRTSurvey()) {
      if (!(country in MhtEligibleCountries)) {
        dispatch(failLocationMedValidation());
        return ["country"];
      }

      if (state in MhtIneligibleStates) {
        dispatch(failLocationMedValidation());
        return ["postcode"];
      }
    }
    return [];
  }

  function enteredAddressMatchesChosenAddress() {
    if (Object.keys(chosenModalAddress).length > 0) {
      const enteredAddress = selectResolvedPhysicalAddress(coreState);
      return !Object.keys(chosenModalAddress).some(
        (addressField) =>
          chosenModalAddress[addressField] !== enteredAddress[addressField]
      );
    }
    return false;
  }

  async function getPhysicalAddressValidation() {
    if (acceptedSuggestedAddress && enteredAddressMatchesChosenAddress()) {
      return [];
    }

    try {
      await dispatch(requestAddressValidation({})).unwrap();
    } catch {
      return ["invalidPhysicalAddress"];
    }
    return [];
  }

  const validatePhysicalAddressForm =
    requiresPhysicalAddress(store.getState()) && !isSameAsBillingAddress;

  const validateForm = useCallback((): AdditionalErrorFields => {
    if (!validatePhysicalAddressForm) {
      return null;
    }
    const baseValidation = {
      address1: !address.address1 ? {} : null,
      city: !address.city ? {} : null,
      region: !address.region ? {} : null,
      postcode: !isValidZipCode(address.zipcode, address.country) ? {} : null,
      country: !address.country ? {} : null,
      firstName: !address.firstName && showNameFields ? {} : null,
      lastName: !address.lastName && showNameFields ? {} : null,
    };

    return {
      ...baseValidation,
      ...getTrErrorState({ address, suggestedAddress, addressInvalid }),
      ...getAddressLineErrorState({ isAddressLineInvalid }),
    };
  }, [
    address,
    addressInvalid,
    suggestedAddress,
    validatePhysicalAddressForm,
    showNameFields,
    isAddressLineInvalid,
  ]);

  const hasPhysicalAddressErrors = useCallback(() => {
    const errors = validateForm();
    return errors && typedKeys(errors).some((key) => errors[key]);
  }, [validateForm]);

  return {
    updatePhysicalAddress,
    address,
    isSameAsBillingAddress,
    togglePhysicalAddressSameAsBilling,
    validateForm,
    hasPhysicalAddressErrors,
    shouldShowSameAsBillingAddressToggle,
    getNoPOBoxInAddressValidation,
    getAddressLineMDIValidation,
    getZipCodeStateEligibleValidation,
    getPhysicalAddressValidation,
    acceptedSuggestedAddress,
    shouldUpdateFormAddress,
    showNameFields,
  };
}

export function getTrErrorState({
  address,
  suggestedAddress,
  addressInvalid,
}: {
  address: BillingAddress;
  suggestedAddress: Partial<BillingAddress>;
  addressInvalid: boolean;
}): AdditionalErrorFields {
  // TR validation request didn't return invalid, or they already started correcting
  if (!addressInvalid) {
    return null;
  }
  // No suggestions available; give them a generic invalid message
  if (!suggestedAddress || Object.keys(suggestedAddress).length === 0) {
    return {
      address1: {
        type: "notFound",
        message: i18n.t("payment:homeAddressErrors:notFound"),
      },
    };
  }
  // Otherwise, give them a message for the fields that differ
  const keys = ["address1", "city", "region", "zipcode"];
  const ret: AdditionalErrorFields = {};
  keys.forEach((key) => {
    if (suggestedAddress[key] && suggestedAddress[key] !== address[key]) {
      const errorKey = key === "zipcode" ? "postcode" : key;
      ret[errorKey] = {
        type: "notRecognized",
        message: i18n.t("payment:homeAddressErrors:notRecognized"),
      };
    }
  });
  return ret;
}

function getAddressLineErrorState({
  isAddressLineInvalid,
}: {
  isAddressLineInvalid: boolean;
}): AdditionalErrorFields {
  // AddressLineMDIValidation didn't return invalid
  if (!isAddressLineInvalid) {
    return null;
  }

  return {
    address1: {
      type: "tooLong",
      message: i18n.t("payment:homeAddressErrors:tooLong"),
    },
  };
}

/**
 * Split the address line based on MDI length limitation
 */
export function getSplitAddressLine({
  address1,
  address2,
}: {
  address1: string;
  address2?: string;
}) {
  if (
    address1.length < MAX_MDI_ADDRESS_LINE_LENGTH &&
    address2?.length < MAX_MDI_ADDRESS_LINE_LENGTH
  ) {
    return {
      addressLine1: address1,
      addressLine2: address2,
    };
  }

  const SPLIT_REGEX = /[" ,"]/g;
  const REPLACE_REGEX = /^[, ]+|[, ]+$/g;
  const addressLine = address2 ? address1.concat(", ", address2) : address1;

  if (addressLine.search(SPLIT_REGEX) === -1) {
    return {
      addressLine1: address1,
      addressLine2: address2,
    };
  }

  const matchArray = [...addressLine.matchAll(SPLIT_REGEX)].filter(
    ({ index }) => index <= MAX_MDI_ADDRESS_LINE_LENGTH
  );
  const { index } = matchArray[matchArray.length - 1];

  const addressLine1 = addressLine
    .substring(0, index)
    .replace(REPLACE_REGEX, "");
  const addressLine2 = addressLine.substring(index).replace(REPLACE_REGEX, "");

  return {
    addressLine1,
    addressLine2,
  };
}
